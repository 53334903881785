<template>
    <div class="recruitment">
        <Header></Header>
        <div class="recruitment_content">
            <div class="banner_wrap">
                <img src="~@/assets/common/bg3.png" alt="">
            </div>
            <div class="hint_wrap">
                <div class="text1">来吧，加入我们！</div>
                <div class="text2">我们需要有才华的你</div>
            </div>
            <div class="post_wrap">
                <div class="post_wrap_content">
                    <div class="list" @click="detail('java')">
                        <div class="icon">
                            <img src="~@/assets/common/java_icon1.png" alt="" class="icon1">
                            <img src="~@/assets/common/java_icon2.png" alt="" class="icon2">
                        </div>
                        <div class="name">
                            Java开发工程师及创业伙伴
                        </div>
                        <div class="desc">
                            2~3个空缺职位
                        </div>
                    </div>
                    <div class="list" @click="detail('web')">
                        <div class="icon">
                            <img src="~@/assets/common/web_icon1.png" alt="" class="icon1">
                            <img src="~@/assets/common/web_icon2.png" alt="" class="icon2">
                        </div>
                        <div class="name">
                            Web前端开发工程师
                        </div>
                        <div class="desc">
                            2~3个空缺职位
                        </div>
                    </div>
                    <div class="list" @click="detail('ui')">
                        <div class="icon">
                            <img src="~@/assets/common/ui_icon1.png" alt="" class="icon1">
                            <img src="~@/assets/common/ui_icon2.png" alt="" class="icon2">
                        </div>
                        <div class="name">
                            UI设计师
                        </div>
                        <div class="desc">
                            1~2个空缺职位
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
    data(){
        return{
        }
    },
    components:{
        Header,
        Footer
    },
    methods:{
        detail(type){
            this.$router.push('/recruitment_detail?type='+type)
        }
    }
}
</script>

<style lang="less">
    .recruitment_content{
        .banner_wrap{
            text-align: center;
        }
        .hint_wrap{
            text-align: center;
            color: #384555;
            font-weight: bold;
            .text1{
                font-size: 28px;
            }
            .text2{
                font-size: 22px;
                padding-left: 20px;
                padding-top: 10px;
            }
        }
        .post_wrap{
            background: #fff;
            .post_wrap_content{
                max-width: @max_width;
                margin: 80px auto;
                display: flex;
                justify-content: space-between;
                .list{
                    cursor: pointer;
                    width: 30%;
                    min-height: 200px;
                    padding: 80px 0;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.04);
                    border-radius: 8px;
                    text-align: center;
                    background: #fff;
                    transition: all 0.3s ease;
                    .icon{
                        .icon1{
                            display: inline-block;
                        }
                        .icon2{
                            display: none;
                        }
                    }
                    .name{
                        font-size: 20px;
                        padding: 20px 0;
                        color: #384555;
                        font-weight: bold;
                    }
                    .desc{
                        font-size: 20px;
                        color: #8492A6;
                    }
                    &:hover{
                        background: linear-gradient(150deg, #47EBDF, #01B671);
                        box-shadow: 0px 0 10px 1px #47EBDF;
                        transition: all 0.3s ease;
                        .icon{
                            .icon1{
                                display: none;
                            }
                            .icon2{
                                display: inline-block;
                            }
                        }
                        .name,.desc{
                            color: #fff;
                        }
                    }
                    
                }

            }
        }
    }
</style>